/* Footer container */
footer {
  margin: 20px;
  text-align: center;
  align-self: flex-end;
  font-size: 10px;
  margin-top: 53px;
}

.footer-icons {
  display: flex;
  width: 50%;
  margin: auto;
  justify-content: space-evenly;
  transform: scale(1.2); /* Pop effect */
}

.footer-icons a {
  color: black;
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.footer-icons a:hover {
  transform: scale(1.2); /* Pop effect on hover */
}
