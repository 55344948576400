/* General styling */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: relative;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Hamburger menu */
.hamburger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  z-index: 10;
  position: relative;
  top: -8px;
}

.hamburger .line {
  width: 100%;
  height: 3px;
  background-color: black;
  transition: all 0.3s ease;
}

/* Hamburger open to X transition */
.hamburger.open .line:nth-child(1) {
  transform: rotate(45deg) translate(7px, 6px);
  background-color: white;
}

.hamburger.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger.open .line:nth-child(3) {
  transform: rotate(-45deg) translate(8px, -6px);
  background-color: white;
}

/* Logo styling */
.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.logo img {
  max-width: 75px;
}

/* Mobile logo styling */
@media only screen and (max-width: 600px) {
  .logo {
    right: 10px;
    left: auto;
    transform: none;
  }

  .logo img {
    width: 35px;
  }
}

/* Nav menu styling */
.nav-menu {
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #000;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: top 0.3s ease;
  z-index: 10;
  padding: 47px 0;
}

.nav-menu.slide-in {
  top: 0;
}

.nav-menu.slide-out {
  top: -400%;
}

/* Links inside nav menu */
.nav-menu span,
.nav-menu a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
}
