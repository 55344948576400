@font-face {
  font-family: "Hermit";
  src: url("/public/fonts/HurmitNerdFont-Regular.otf") format("woff2"),
    url("/public/fonts/HurmitNerdFont-Regular.otf") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Hermit", sans-serif;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

main {
  position: relative;
  top: 20px;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: black;
}

h1 {
  font-size: 30px;
}
header {
  margin: 20px 0;
  text-align: center;
}

nav {
  text-align: -webkit-center;
}

nav ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (min-width: 768px) {
  nav ul {
    flex-direction: row; /* Display menu items in a row on larger screens */
    justify-content: center;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes reversed-border {
  0% {
    border-color: rgb(0, 0, 0); /* Starts at black */
  }
  20% {
    border-color: rgb(49, 48, 48);
  }
  40% {
    border-color: rgb(69, 69, 69);
  }
  60% {
    border-color: rgb(154, 155, 154);
  }
  80% {
    border-color: rgb(190, 190, 190);
  }
  100% {
    border-color: rgb(255, 255, 255); /* Ends at white */
  }
}

.content-wrapper {
  flex-grow: 1;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 13px;
  width: 90vw;
  max-width: 900px;
  margin: 40px auto;
  border: 6px solid;
  background-color: white;
  animation: reversed-border 5s linear infinite alternate;
  display: grid;
}
