/* Typewriter effect */
.typewriter {
  font-family: "Hermit", monospace;
  display: inline-block;
  overflow: hidden;
  min-height: 23px;
  max-width: 500px;
}

/* Profile image with rolling animation */
.profile-img {
  max-width: 125px;
  filter: drop-shadow(2px 4px 6px black);
  border-radius: 200px;
}

.profile-img.animate {
  max-width: 125px;
  filter: drop-shadow(2px 4px 6px black);
  border-radius: 200px;
  animation: roll-in 2s ease-out;
  animation-iteration-count: 1; /* Run the animation only once */
}

/* Keyframes for rolling animation */
@keyframes roll-in {
  0% {
    transform: translateX(-100vw) rotate(-720deg);
  }
  100% {
    transform: translateX(0) rotate(0);
  }
}

/* Caret animation */
.caret {
  display: inline-block;
  width: 1px;
  background-color: rgb(255, 255, 255);
  animation: blink 0.5s infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.bounce {
  animation: bounce 0.3s ease-in-out; /* Faster bounce animation */
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Slightly less height for a faster bounce */
  }
}
